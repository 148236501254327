import React from 'react';
import { Tabs, Tab, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { colors } from '../../theme/colors';

interface OptionTabsProps {
  value: number;
  onChange: (event: React.SyntheticEvent, newValue: number) => void;
  tabs: string[];
}

const StyledTabs = styled(Tabs)({
  position: 'sticky',
  top: 0,
  backgroundColor: 'white',
  zIndex: 1,
  borderBottom: 1,
  borderColor: 'divider',
  width: '100%',
  '& .MuiTab-root': {
    fontFamily: 'Poppins, sans-serif',
    fontSize: '16px',
    textTransform: 'none',
    flex: 1,
    padding: '6px 0px 4px 0px',
    maxWidth: 'none'
  },
  '& .Mui-selected': {
    color: `${colors.purple500} !important`
  },
  '& .MuiTabs-indicator': {
    backgroundColor: colors.purple500
  }
});

const TabContainer = styled(Box)({
  borderBottom: 1,
  borderColor: 'divider',
  width: '100vw',

});


export const OptionTabs: React.FC<OptionTabsProps> = ({ value, onChange, tabs }) => {
  return (
    <TabContainer zIndex={1}>
      <StyledTabs 
        value={value} 
        onChange={onChange}
        variant="fullWidth"
        centered
      >
        {tabs.map((tab, index) => (
          <Tab key={index} label={tab} />
        ))}
      </StyledTabs>
    </TabContainer>
  );
};
