import React, { FC, useEffect, useState } from 'react';
import GlobalChannelsList from '../liveshow/GlobalChannelsList';
import { fetchAgoraChannels } from '../../lib/live';
import ProgressSpinner from '../../components/ProgressSpinner';
import Stack from '@mui/material/Stack';

const AllLives = ({stickyMuteButton}: { stickyMuteButton?: boolean }) => {
  type Channel = ({
    channel_name: string;
    user_count?: number;
  });

  const [channelsInfo, setChannelsInfo] = useState<{
    token: string | null,
    uid: number | null,
    liveChannels: Channel[] | null,
    jatangoChannel: Channel | null,
    pastChannels: { shopSlug: string; showSlug: string; src: string; timestamp: Date }[] | null
  }>({
    token: null,
    uid: Math.floor(Math.random() * 10000),
    jatangoChannel: null,
    liveChannels: null,
    pastChannels: null
  });

  const findJatangoChannel = (channels: Channel[]) =>
    channels.find(channel => channel.channel_name?.includes('jatango')) || null;


  const fetchChannelsInfo = async () => {
    try {
      const liveChannels = await fetchAgoraChannels();

      const jatangoChannel = findJatangoChannel(liveChannels);
      const filteredLiveChannels = liveChannels.filter((channel: { channel_name: string, user_count: number }) => !channel.channel_name.includes('jatango'))

      const listOfPastLives = await fetch(`/api/live/recording/get-all-recordings`)

      const pastChannels = await listOfPastLives.json();

      const response = await fetch(`/api/live/create-rtc-token`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          uid: channelsInfo?.uid,
          role: "audience",
        }),
      });

      if (!response.ok) {
        throw new Error(`Failed to fetch token: ${response.statusText}`);
      }

      const data = await response.json();

      setChannelsInfo((prev) => {
        return {
          ...prev,
          token: data.token,
          jatangoChannel,
          liveChannels: filteredLiveChannels,
          pastChannels
        }
      })
    } catch (error) {
      console.error("Error fetching token:", error);
      return {
        props: {},
      };
    }

  };

  useEffect(() => {
    fetchChannelsInfo()
  }, []);

  return (
    <>
      {
        channelsInfo.token ? <GlobalChannelsList stickyMuteButton={stickyMuteButton} token={channelsInfo.token as string} uid={channelsInfo?.uid as number} jatangoChannel={channelsInfo?.jatangoChannel} liveChannels={channelsInfo?.liveChannels!} pastChannels={channelsInfo?.pastChannels!} /> : <Stack height="100vh" justifyContent="center" alignItems="center"><ProgressSpinner /></Stack>
      }
    </>
  );
};


export default AllLives;





