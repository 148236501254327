import { Box, Stack, useMediaQuery } from '@mui/material';
import { colors } from '@/theme/colors';
import React, { Dispatch, FC, SetStateAction, useEffect, useRef, useState } from 'react';
import CustomCard from '@/components/cards/Card';
import { Heading } from '@/theme/text-variants';
import { createTheme, ThemeProvider } from '@mui/material';
import { initializeAgoraRTCClient, joinAsAudience, RTC } from '@/lib/live';
import { useRouter } from 'next/router';
import Tag from '@/componentsV2/Tag';
import UserTag from '@/componentsV2/UserTag';
import { Show, useShowBySlugPublic } from '@/api';
import { colorTokens } from '../../theme/color-tokens';
import { IAgoraRTCRemoteUser } from 'agora-rtc-sdk-ng';
import VolumeOffRoundedIcon from '@/img/icons/volume_off.svg';
import JatangoShowBg from '@/img/jatango-show.png';
import styled from 'styled-components';

const theme = createTheme();

interface GlobalChannelsListProps {
  token: string;
  uid: number;
  jatangoChannel?: Channel | null;
  liveChannels: Channel[];
  pastChannels: { shopSlug: string; showSlug: string; src: string; blobName?: string; timestamp?: any }[];
  stickyMuteButton?: boolean;
  // setPage?: Dispatch<SetStateAction<number>>;
}

type Channel = {
  channel_name: string;
  user_count?: number;
};

const LiveShowCard = ({
  show,
  channelName,
  banner
}: {
  show: Show;
  channelName: string;
  banner: string | null | undefined;
}) => (
  <ShowCard
    contentPadding="0px"
    style={{ userSelect: 'none', backgroundImage: `url(${banner})`, backgroundSize: 'cover' }}
  >
    <ShowTag variant="alert" size="sm" label="Live" dark />
    <Stack
      style={{ width: '100%', height: '256px', position: 'relative', backgroundColor: 'transparent' }}
      id={channelName}
    >
      <ShowHeadingBox id={channelName}>
        <Heading style={{ wordBreak: 'break-all' }} size="sm" shadow color={colors.white}>
          {show?.name?.length > 40 ? show?.name?.slice(0, 40).concat('...') : show?.name}
        </Heading>
      </ShowHeadingBox>
    </Stack>
  </ShowCard>
);

const GlobalChannelsList: FC<GlobalChannelsListProps> = ({
  token,
  uid,
  liveChannels,
  pastChannels,
  jatangoChannel,
  stickyMuteButton = false
}) => {
  const { query, push } = useRouter();
  const matchesMobileSize = useMediaQuery('(min-width:460px)');

  // Jatango Show
  const jatangoShopSlug = (jatangoChannel as Channel)?.channel_name.split('_')[0];
  const jatangoShowSlug = (jatangoChannel as Channel)?.channel_name.split('_')[1];
  const { show: jatangoShow } = useShowBySlugPublic(jatangoShopSlug, jatangoShowSlug);
  const jatangoShowImageUrl = jatangoShow?.showImages?.[0]?.image?.transformedSrc;

  const [hoveredChannel, setHoveredChannel] = useState<string | null>(null);
  const [remoteUser, setRemoteUser] = useState<IAgoraRTCRemoteUser>();
  const [muteMainVideo, setMuteMainVideo] = useState<boolean>(false);
  const [videosUnmuted, setVideosUnmuted] = useState(() => {
    if (typeof window !== 'undefined') {
      return localStorage.getItem('videosUnmuted') === 'true';
    }
    return false;
  }); // const [jtVideoUrl, setJtVideoUrl] = useState<string | null>(null);
  const [audioAutoplayFailed, setAudioAutoplayFailed] = useState(false);
  const [options, setOptions] = useState({
    appId: process.env.NEXT_PUBLIC_AGORA_APP_ID as string,
    channel: null,
    token,
    uid
  });

  const rtc = useRef<RTC>({
    localAudioTrack: null,
    localVideoTrack: null,
    client: null,
    compositor: null
  }).current;

  /* To disable download popup on long-click of the video tag */
  useEffect(() => {
    const preventDownload = (event: any) => {
      if (event.target.tagName === 'VIDEO') {
        event.preventDefault();
      }
    };
    document.addEventListener('contextmenu', preventDownload);

    return () => {
      document.removeEventListener('contextmenu', preventDownload);
    };
  }, []);

  useEffect(() => {
    if (options.token && options.uid) {
      initializeAgoraRTCClient(rtc, false);
    }
  }, [options.token, options.uid]);

  const displayRemoteVideo = (user: any, elementId: string) => {
    const remotePlayerContainer = document.getElementById(elementId) as HTMLElement;
    user?.videoTrack?.play(remotePlayerContainer);
    user?.audioTrack?.play(remotePlayerContainer);
  };

  const handleRemoteUsers = (
    videoPlayerContainerId?: string,
    onRemoteUserJoined?: (user: IAgoraRTCRemoteUser) => void,
    onAudioAutoPlayFailure?: () => void
  ) => {
    rtc.client?.on('user-published', async (user, mediaType) => {
      await rtc.client?.subscribe(user, mediaType);
      console.log('subscribe success');

      if (mediaType === 'video') {
        displayRemoteVideo(user, videoPlayerContainerId ?? hoveredChannel!);
      }

      if (mediaType === 'audio') {
        // user?.audioTrack?.play()

        if (!user?.audioTrack?.isPlaying) {
          onAudioAutoPlayFailure?.();
        }
      }

      onRemoteUserJoined?.(user);
    });

    rtc.client?.on('user-unpublished', (user) => {
      const remotePlayerContainer = document.getElementById(videoPlayerContainerId ?? hoveredChannel!) as HTMLElement;
      user?.videoTrack?.stop();
      user?.audioTrack?.stop();
    });
  };

  useEffect(() => {
    if (jatangoShow && jatangoChannel) {
      joinAsAudience(rtc, { ...options, channel: jatangoChannel.channel_name }).then(() =>
        handleRemoteUsers(
          jatangoChannel.channel_name,
          (user) => {
            setHoveredChannel(null);
            // user?.audioTrack?.play();
            setRemoteUser(user);
          },
          () => {
            setAudioAutoplayFailed(true);
          }
        )
      );
    }
  }, [jatangoShow]);

  return (
    <ThemeProvider theme={theme}>
      <Container>
        <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
          <Stack
            display={'flex'}
            direction={'row'}
            alignItems={'center'}
            justifyContent={'center'}
            flexWrap="wrap"
            gap={2}
          >
            {liveChannels.map(({ channel_name }, index) => {
              const [shopSlug, showSlug] = channel_name.split('_');
              const { show } = useShowBySlugPublic(shopSlug, showSlug);

              const imageUrl = show?.showImages?.[0]?.image?.transformedSrc;
              return (
                <VideoListContainer
                  isMobile={matchesMobileSize}
                  firstPosition={index === 0}
                  key={channel_name}
                  onTouchStart={() => {
                    setMuteMainVideo(true);
                    setMuteMainVideo(true);
                    joinAsAudience(rtc, { ...options, channel: channel_name }).then(() =>
                      handleRemoteUsers(
                        channel_name,
                        (user) => setRemoteUser(user),
                        () => {
                          setAudioAutoplayFailed(true);
                        }
                      )
                    );
                  }}
                  onTouchEnd={async () => {
                    setHoveredChannel(null);
                    await rtc?.client?.leave();
                  }}
                  onMouseEnter={() => {
                    setMuteMainVideo(true);
                    setMuteMainVideo(true);
                    setHoveredChannel(channel_name);
                    joinAsAudience(rtc, { ...options, channel: channel_name }).then(() =>
                      handleRemoteUsers(
                        channel_name,
                        (user) => setRemoteUser(user),
                        () => {
                          setAudioAutoplayFailed(true);
                        }
                      )
                    );
                  }}
                  onMouseLeave={async () => {
                    setHoveredChannel(null);
                    await rtc?.client?.leave();
                  }}
                  onClick={(e) => {
                    // Check if the event was triggered by a touch
                    if (e.detail === 0) return; // `e.detail` is 0 for touch events, greater than 0 for mouse clicks
                    // console.log('Card clicked:', channel_name);
                    window.location.assign(`${window.location.origin}/live/${shopSlug}/${showSlug}/v3`);
                  }}
                >
                  {show?.affiliateShopId ? (
                    <UserTag imgSrc={show?.affiliateShop?.shopCustomizations?.[0]?.shopProfileImage?.originalSrc} label={show?.affiliateShop?.name} size="sm" />
                  ) : (
                    <UserTag imgSrc={show?.shop?.shopCustomizations?.[0]?.shopProfileImage?.originalSrc} label={show?.shop?.name} size="sm" />
                  )}
                  <LiveShowCard banner={imageUrl} show={show as Show} channelName={channel_name} />
                </VideoListContainer>
              );
            })}

            {Array.isArray(pastChannels) &&
              pastChannels
                .sort((a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime())
                ?.map((pastChannel, index) => {
                  const { shopSlug, showSlug, blobName } = pastChannel;
                  const { show } = useShowBySlugPublic(shopSlug, showSlug);

                  const imageUrl = show?.showImages?.[0]?.image?.transformedSrc;

                  if (!show) return <></>;

                  return (
                    <VideoListContainer
                      isMobile={matchesMobileSize}
                      firstPosition={!liveChannels.length && index === 0}
                      key={index}
                      onClick={() => {
                        window.location.assign(
                          `${window.location.origin}/lives/recorded?bn=${blobName}&ss=${shopSlug}`
                        );
                      }}
                    >
                      {show?.affiliateShopId ? (
                        <UserTag label={show?.affiliateShop?.name} size="sm" />
                      ) : (
                        <UserTag label={show?.shop?.name} size="sm" />
                      )}
                      <ShowCard contentPadding="0px">
                        <ShowTag variant="neutral" size="sm" label="Ended" dark />
                        <ShowHeadingBox>
                          <Heading style={{ wordBreak: 'break-all' }} size="sm" shadow color={colors.white}>
                            {show?.name?.length > 40 ? show?.name?.slice(0, 40).concat('...') : show?.name}
                          </Heading>
                        </ShowHeadingBox>
                        <Video
                          playsInline
                          style={{ width: '100%', height: '256px' }}
                          onTouchStart={(e) => e.currentTarget.play()}
                          onTouchEnd={(e) => {
                            e.currentTarget.pause();
                            e.currentTarget.currentTime = 0;
                            e.currentTarget.load(); // Reload to display the poster
                          }}
                          onMouseEnter={(e) => e.currentTarget.play()}
                          onMouseLeave={(e) => {
                            e.currentTarget.pause();
                            e.currentTarget.currentTime = 0;
                            e.currentTarget.load(); // Reload to display the poster
                          }}
                          poster={imageUrl as string}
                          muted={!videosUnmuted}
                          controls={false}
                          controlsList="nodownload"
                        >
                          <source src={pastChannel.src}></source>
                        </Video>
                      </ShowCard>
                    </VideoListContainer>
                  );
                })}
          </Stack>
        </Box>
        {!videosUnmuted && (
          <Stack
            width="100%"
            position={stickyMuteButton ? 'sticky' : 'fixed'}
            zIndex={1000}
            bottom="72px"
            right="16px"
            alignItems="flex-end"
          >
            <Stack width="fit-content">
              <SoundButton
                onClick={() => {
                  setVideosUnmuted(true);
                  localStorage.setItem('videosUnmuted', 'true');
                  remoteUser?.audioTrack?.play();
                  setAudioAutoplayFailed(false);
                }}
              >
                <Stack alignItems="center">
                  <VolumeOffRoundedIcon />
                  Sound
                </Stack>
              </SoundButton>
            </Stack>
          </Stack>
        )}
      </Container>
    </ThemeProvider>
  );
};

export default GlobalChannelsList;

const Container = styled(Stack)({
  // maxWidth: "530px",
  width: '100%',
  margin: 'auto',
  // padding: '16px',
  // backgroundColor: colorTokens.backgroundScreen,
  position: 'relative',
  webkitUserSelect: 'none',
  mozuserSelect: 'none',
  msUserSelectelect: 'none',
  userSelect: 'none'
});

const VideoListContainer = styled(Stack)<{ firstPosition: boolean; isMobile: boolean }>(
  ({ firstPosition, isMobile }) => ({
    position: 'relative',
    webkitUserSelect: 'none',
    mozuserSelect: 'none',
    msUserSelectelect: 'none',
    userSelect: 'none',
    cursor: 'pointer',
    width: `${isMobile ? (firstPosition ? '420px' : '200px') : 'calc(50% - 8px)'}`
  })
);

const SoundButton = styled('button')(() => ({
  backgroundColor: 'rgba(255, 255, 255, 1)',
  border: 'none',
  borderRadius: '8px',
  height: 'auto',
  padding: '8px',
  width: 'auto',
  fontFamily: 'Inter',
  fontWeight: 'bold',
  fontSize: '14px',
  color: colorTokens.iconAlert,

  '& svg': {
    width: '24px',
    height: '24px'
  }
}));

const ShowCard = styled(CustomCard)({
  position: 'relative',
  margin: '4px 0px 0px',
  height: '256px',
  overflow: 'hidden'
});

const ShowHeadingBox = styled(Box)({
  position: 'absolute',
  background: 'linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.16) 5%, #000 100%)',
  padding: '4px 8px 8px',
  left: 0,
  right: 0,
  bottom: 0,
  borderRadius: '8px',
  zIndex: 1,
  display: 'flex',
  alignItems: 'center'
});

const ShowTag = styled(Tag)({
  position: 'absolute',
  top: '35px',
  left: '7px',
  pointerEvents: 'none',
  zIndex: 1
});

const Video = styled('video')({
  objectFit: 'cover'
});
