import React, { FC, useState } from 'react';
import { styled } from '@mui/material/styles';
import { Card, CardMedia, Typography, Stack, Chip, Box } from '@mui/material';
import { Paragraph } from '../../theme/text-variants';
import StoreFrontIcon from '../../img/storefrontpurple.svg';
import { colors } from '../../theme/colors';
import { colorTokens } from '../../theme/color-tokens';
import StoreImage from '../../img/StoreImage.svg';

interface StoreDetailsCardProps {
  storeName: string;
  categories: any[];
  commissionRate: number;
  profileImage?: string;
  onClick?: () => void;
}

const StoreDetailsCard: FC<StoreDetailsCardProps> = ({
  storeName,
  categories,
  commissionRate,
  profileImage,
  onClick
}) => {
  return (
    <StoreCard onClick={onClick}>
      <Box>
        <Stack direction="row">
          <ProfileContainer>
            {profileImage ? <img src={profileImage} alt={storeName} /> : <StoreImage />}
          </ProfileContainer>

          <Stack direction="column" pt={1} pl={1}>
            <StoreNameContainer>
              <StoreFrontIcon sx={{ backgroundColor: colors.white }} />
              <Paragraph size="lg" fontWeight="bold">
                {storeName}
              </Paragraph>
            </StoreNameContainer>

            <CategoriesStack direction="row" spacing={1}>
              {categories.map((category) => (
                <CategoryChip key={category} label={category} variant="filled" size="small" />
              ))}
            </CategoriesStack>
          </Stack>
        </Stack>
        <CommissionBadge>
          <CommissionLabel size="sm" fontWeight="bold">
            Commission Paid:
          </CommissionLabel>
          <CommissionValue size="sm">{commissionRate}%</CommissionValue>
        </CommissionBadge>
      </Box>
    </StoreCard>
  );
};
export default StoreDetailsCard;
const StoreCard = styled(Card)(({ theme }) => ({
  borderRadius: theme.spacing(2),
  boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
  overflow: 'hidden',
  position: 'relative',
  backgroundColor: colors.white
}));

const CategoryChip = styled(Chip)(({ theme }) => ({
  borderRadius: theme.spacing(1),
  backgroundColor: `${colors.gray100}`,
  color: `${colorTokens.textSubtle}`,
  fontWeight: 'bold',
  margin: theme.spacing(0, 0.5),
  '&:first-of-type': {
    marginLeft: 0
  }
}));

const CategoriesStack = styled(Stack)({
  display: 'flex',
  maxWidth: '250px',
  paddingBottom: '8px',
  flexWrap: 'nowrap',
  overflowY: 'auto',
  alignContent: 'flex-start',
  '&::-webkit-scrollbar': {
    display: 'none'
  }
});

const CommissionBadge = styled(Box)(({ theme }) => ({
  backgroundColor: colors.green100,
  borderRadius: theme.spacing(1),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  position: 'absolute',
  bottom: '-4px',
  right: '-6px'
}));

const CommissionLabel = styled(Paragraph)({
  margin: '8px'
});

const CommissionValue = styled(Paragraph)(({ theme }) => ({
  backgroundColor: '#fff175',
  color: `${colorTokens.textDefault}`,
  padding: theme.spacing(0.5, 1),
  margin: theme.spacing(1, 1.5),
  borderRadius: theme.spacing(1),
  fontWeight: 'bold'
}));

const StoreNameContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '8px'
});

const ProfileContainer = styled(Box)(({ profileImage }: { profileImage?: string }) => ({
  width: '108px',
  height: '108px',
  padding: '8px',
  flexShrink: 0,
  '& img': {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    boxShadow: '-1px 2px 4px 0px #00000080',
    borderRadius: '8px'
  }
}));
